<template>

  <div class="app de">
    <div class="hd">
      <span @click="$router.back()">返回</span>
      <div>我的活动</div>
      <div></div>

    </div>
    <div class="ct" style="overflow-y: auto">
      <md-tabs>
        <md-tab-pane class="content" name="p1" label="即将开始">
          <ul>
            <li v-for="item of willlist" :key="item.id">
              <div style="font-size: 16px;"> {{item.title}} </div>
              <div>
              <div>
                活动时间: {{ new Date(item.sTime) | format('YYYY-MM-DD HH:mm') }} 至 {{ new Date(item.eTime) | format('YYYY-MM-DD HH:mm') }}
              </div>
                <div>预约时间: {{ new Date(item.action.addTime) | format('YYYY-MM-DD HH:mm') }} </div>
                <div>
                  审核状态:
                  {{ item.action.state == 1 ? '预约':'' }}
                  {{ item.action.state == 2 ? '等待确认':'' }}
                  {{ item.action.state == 3 ? '成功预约':'' }}
                  {{ item.action.state== -1 ? '未通过':'' }}
                </div>
                <div>备注: {{item.action.beizhu }} </div>

              </div>
            </li>
          </ul>
        </md-tab-pane>
        <md-tab-pane class="content" name="p2" label="过期的预约">
          <ul>
            <li v-for="item of endlist" :key="item.id">
              <div style="font-size: 16px;"> {{item.title}} </div>
              <div>
                <div>
                  活动时间: {{ new Date(item.sTime) | format('YYYY-MM-DD HH:mm') }} 至 {{ new Date(item.eTime) | format('YYYY-MM-DD HH:mm') }}
                </div>
                <div>预约时间: {{ new Date(item.action.addTime) | format('YYYY-MM-DD HH:mm') }} </div>

                <div>
                  审核状态:
                  {{ item.action.state == 1 ? '预约':'' }}
                  {{ item.action.state == 2 ? '等待确认':'' }}
                  {{ item.action.state == 3 ? '成功预约':'' }}
                  {{ item.action.state== -1 ? '未通过':'' }}
                </div>
                <div>备注: {{item.action.beizhu }} </div>

              </div>
            </li>
          </ul>
        </md-tab-pane>

      </md-tabs>
    </div>

  </div>

</template>

<script>
export default {
  name: "myac",
  data(){
    return {
      willlist:[],
      endlist:[]
    }
  },
  mounted() {
    this.getData();
  },
  methods:{
    async getData(){
      let res1 = await this.$api.get('/api/activity?type=will&uid='+this.$store.state.userInfo.uid)
      this.willlist =  res1.data.rows;
      let res2 = await this.$api.get('/api/activity?type=end&uid='+this.$store.state.userInfo.uid)
      this.endlist = res2.data.rows
    },
    async dologin(){
      if(!this.user.phone || this.user.phone.length!==11) return this.$toast.info('请填写正确的手机号码')
      const res = await this.$api.post('/mapi/login',{ phone:this.user.phone,pwd:this.user.pwd})
      const { success,info,data } = res.data;
      if(success){
        this.$toast.succeed('登录成功')
        this.$store.commit('setUserInfo',data);
        this.$router.back()
      }else{
        this.$toast.failed(info)
      }


    }
  }

}
</script>

<style scoped lang="scss">
li{
  margin-bottom: 20px;
  font-size: 18px;
  border-bottom: 1px solid #ccc;
  padding: 10px;
}
</style>
